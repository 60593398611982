<template>
  <div>
    <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Settings"/>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
              <v-btn 
                v-show="canDo && canDo.can.includes('add')"
                class="mx-2" depressed dark color="primary" v-on="on">
                <v-icon left dark>mdi-plus-circle</v-icon> Add
              </v-btn>
          </template>
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-form v-model="form_valid" ref="form">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.name" :rules="validate_rules.required" label="Name"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea v-model="editedItem.value" rows="3" :rules="validate_rules.required" label="Value">
                      </v-textarea>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea v-model="editedItem.comment" label="Comments" rows="2">
                      </v-textarea>
                      <!-- <v-text-field v-model="editedItem.comment" label="Comments"></v-text-field> -->
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn v-if="canDo && canDo.can.includes('update')" color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>
      </v-toolbar> 
    <v-divider></v-divider>
    <div class="content-wrap">
      <v-row justify="center">
        <v-col cols="12">
          <v-toolbar class="search-tools pa-0 mb-4" flat>
            <v-spacer></v-spacer>
            <v-col class="d-flex" cols="5" sm="5">
              <v-text-field
                label="Search"
                v-model="search"
                dense
                hide-details
                prepend-inner-icon="mdi-magnify"
                class="hidden-sm-and-down"
              /> 
            </v-col>
          </v-toolbar>
          <v-data-table 
              :items="allSettings" 
              :headers="headers"
              sort-by="updatedAt"
              :search="search"
              :sort-desc="true"
          >
            <template #item.actions="{item}">
                <v-btn 
                  v-if="canDo && (canDo.can.includes('detail') || canDo.can.includes('update'))"
                  class="ma-1" outlined color="primary" icon tile small @click="editItem(item)">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn 
                  v-if="canDo && canDo.can.includes('delete')"
                  class="ma-1" outlined color="red" icon tile small @click="deleteItem(item)">
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
            </template>
            <template #item.updatedAt="{value}">
                <span>{{moment(value).format('DD-MM-YYYY HH:MM:SS')}}</span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';

export default {
  name: 'settings',
  components: {
    pageHeading
  },
  data: () => ({
    dialog: false,
    search : '',
    editedIndex: -1,
    editedItem: {
      id: -1,
      name: '',
      value: '',
      comment: '',
    },
    defaultItem: {
      id: -1,
      name: '',
      value: '',
      comment: '',
    },
    form_valid : false,
    validate_rules : {
      required: [
        v => !!v || 'This field is required',
        // v => v.length <= 10 || 'Name must be less than 10 characters',
      ]
    }
  }),
  methods: {
    ...mapActions(['genCan','getSettings','updateSetting','createSetting','deleteSetting']),

    addItem(){
      // this.$refs.form.resetValidation();
      this.dialog = true;
    },
    
    editItem (item) {
      this.editedIndex = this.allSettings.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
        confirm('Are you sure you want to delete this setting?') && this.deleteSetting(item);
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation() // prevent validate error show again when click add
      }, 300)
    },

    save () {
      if(this.form_valid){
        if (this.editedIndex > -1) {
          this.updateSetting(this.editedItem);
        } else {
          this.createSetting(this.editedItem);
        }
        this.close()
      }
    },
  },
  computed: {
    headers(){
      var columns = [
        {text: "ID", value: 'id'},
        {text: "Name", value: 'name' , width: '200px' },
        {text: "Value", value: 'value', width: '250px' },
        {text: "Comment", value: 'comment', width: '250px'},
        {text: "Updated At", value: 'updatedAt'},
        {text: "Actions", value: 'actions' , sortable: false},
      ];
      if(this.canDo && (this.canDo.can.includes('detail') || this.canDo.can.includes('update') || this.canDo.can.includes('delete'))){
        return columns;
      } else {
        return columns.filter(column => column.value != 'actions');
      }
    },
    formTitle () {
        return this.editedIndex === -1 ? 'New Setting' : 'Edit Setting'
      },
    ...mapGetters(['canDo','allSettings'])
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
  },
  created() {
    this.genCan();
    this.getSettings();
  }
};
</script>